// Angular material styles
@import "@angular/material/theming";
@import "./styles/angular-mat.scss";

// You can add global styles to this file, and also import other style files
.asp-app-root {
  font-size: 10px;
  height: 100%;
  font-weight: 400;
  margin: 0;

  //service appointment
  .breadcrumb-items {
    width: auto;
    padding-right: 5px;
    padding-left: 5px;
    border-bottom: 1px solid #dad8d8;
    line-height: 25px;
    .steps-info {
      color: red;
    }
  }

  .breadcrumb-items:last-child {
    border-bottom: none;
  }
  .ngx-mat-tel-input-container .country-selector {
    background-image: none !important;
    width: 70px !important;
  }
  .ngx-mat-tel-input-container input:not(.country-search) {
    padding-left: 70px !important;
  }

  @media screen and (max-width: 400px) {
    html {
      font-size: 9px !important;
    }
  }

  .testdrive-content {
    .mat-expansion-panel-header,
    .mat-select,
    .mat-form-field-label {
      font-family: var(--font-family);
    }
  }

  @media screen and (max-width: 350px) {
    html {
      font-size: 7.5px !important;
    }
  }

  @media screen and (max-width: 250px) {
    html {
      font-size: 7px !important;
    }
  }

  @media screen and (max-width: 200px) {
    html {
      font-size: 6.5px !important;
    }
  }

  .mat-calendar-table-header {
    color: #212529;
    th {
      font-weight: bold;
    }
  }

  .btn-disabled {
    opacity: 0.3;
  }
  .mat-form-field-flex:hover .mat-form-field-outline {
    color: #0d6aff !important;
  }
  .mat-form-field.mat-focused .mat-form-field-outline {
    color: #0d6aff !important;
    box-shadow: 0px 0px 8px #0d6aff99;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: white !important;
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  .menu-underline {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      transform: translateY(0);
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      background: transparent;
      width: 100%;
      display: table;
      transition: all 0.2s;
    }
    &:hover {
      &::after {
        transform: translateY(4px);
        height: 2px;
        bottom: -2px;
        background: #000;
      }
    }
    &.always-show {
      &::after {
        transform: translateY(0px);
        height: 1px;
        bottom: 0px;
        background: #000;
      }
      &:hover {
        &::after {
          transform: translateY(5px);
          height: 2px;
          bottom: 2px;
          background: #000;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 2px #767676;
  border: 1px solid transparent;
  border-radius: 4px;
}